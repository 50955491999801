<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body p-0">
        <!--begin::Input group-->
        <!-- <div class="row mb-6"> -->
        <div style="height: 64vh; width: 100%">
          <l-map
            v-model="zoom"
            v-model:zoom="zoom"
            :zoomAnimation="true"
            :options="{ zoomControl: false }"
            :center="[-6.304159, 106.724997]"
          >
            <div
              class="card bg-white rounded position-absolute ms-5 mt-10"
              style="z-index: 2"
            >
              <div class="card-body border-top p-5">
                <div class="fs-5 fw-bolder">Keterangan :</div>
                <ul class="list-unstyled mb-0">
                  <li v-for="(item, i) in info" :key="i" class="m-0 p-0">
                    <img class="me-1" :src="item.img" width="40" />
                    <span class="fs-8"> {{ item.text }} </span>
                  </li>
                </ul>
              </div>
            </div>
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
            />
            <l-control-layers />
            <l-control-zoom position="bottomright"></l-control-zoom>

            <template v-for="(item, index) in markers" :key="index">
              <!-- icon -->
              <l-marker
                :lat-lng="[
                  item.advocacy_aleg.latitude,
                  item.advocacy_aleg.longitude,
                ]"
              >
                <l-icon :icon-url="icon" :icon-size="iconSize" />
                <l-popup>
                  <div class="card">
                    <div class="card-body text-start">
                      <div class="fs-5 fw-bolder">
                        {{ item.advocacy_aleg.alamat }}
                      </div>
                      <div class="fw-bold">
                        oleh : {{ item.advocacy_aleg.name }}
                      </div>
                      <div class="text-muted">
                        {{ item.advocacy_aleg.date }}
                      </div>
                      <div class="">
                        {{ item.advocacy_aleg.description }}
                      </div>
                      <img
                        class="img-fluid"
                        style="max-height: 100px"
                        :src="item.advocacy_aleg.foto"
                        alt="foto kegiatan"
                      />
                    </div>
                  </div>
                </l-popup>
              </l-marker>
            </template>
          </l-map>
        </div>
        <!-- </div> -->
        <!--end::Input group-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "Heatmaps",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LPopup,
  },
  setup() {
    setCurrentPageTitle("Peta Zona");

    const store = useStore();
    // const router = useRouter();

    store.dispatch("AllAdvocacyDashboard");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";

    const icon = path + "other/png/advokasii-4.png";

    const markers = computed(() => store.state.AdvocacyDashboardModule.all);
    // kalau di group butuh param icon mark

    const info = ref([
      {
        img: "other/png/advokasii-4.png",
        text: "Advokasi Aleg A",
      },
      {
        img: "other/png/advokasii-5.png",
        text: "Advokasi Aleg B",
      },
      {
        img: "other/png/advokasii-3.png",
        text: "Advokasi Aleg C",
      },
    ]);

    return {
      center,
      markers,
      info,
      zoom,
      icon,
      iconSize,
    };
  },
});
</script>
